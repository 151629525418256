<template lang="">
  <section>
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <div>
            <TitlePlus
              :title="
                $t(
                  'components.riderDetailsManagement.diveIntoTab.vouchers.headline'
                )
              "
              :hide-plus="true"
            />
          </div>

          <div class="flex md:justify-end">
            <date-range-picker
              @apply-date="onApplyFilterDateRange"
              @cancel-date="onApplyFilterDateRange"
            />
          </div>
        </div>
        <div class="flex flex-col gap-4 mt-4 mb-10 md:items-center md:flex-row">
          <SummaryCard
            :title="
              $t(
                'components.riderDetailsManagement.diveIntoTab.vouchers.summary.invited'
              )
            "
            :value="` ${indexMetaData.summary.total_invited_voucher}`"
            variant="gray"
          />
          <SummaryCard
            :title="
              $t(
                'components.riderDetailsManagement.diveIntoTab.vouchers.summary.redeemed'
              )
            "
            :value="` ${indexMetaData.summary.total_redeemed_voucher}`"
            variant="gray"
          />
          <SummaryCard
            :title="
              $t(
                'components.riderDetailsManagement.diveIntoTab.vouchers.summary.pending'
              )
            "
            :value="` ${indexMetaData.summary.total_pending_voucher}`"
            variant="gray"
          />
          <SummaryCard
            :title="
              $t(
                'components.riderDetailsManagement.diveIntoTab.vouchers.summary.expired'
              )
            "
            :value="` ${indexMetaData.summary.total_expired_voucher}`"
            variant="gray"
          />
        </div>
      </div>
      <FSTable
        :qso="qso"
        :fst-id="fstId"
        :headers="tableHeaders"
        :endpoint="indexDataEndpoint"
        :text-fallback-always="true"
        @meta="(e) => (indexMetaData = e)"
      >
        <template #topLeft="{ slotWidth }">
          <keep-alive>
            <FSTableFilter
              :fst-id="fstId"
              :slot-width="slotWidth"
              :options="filterOptions"
            />
          </keep-alive>
        </template>

        <template #default="{ data }">
          <FSTableRow
            v-for="(item, itemIndex) in data"
            :key="itemIndex"
            textFallbackAlways
          >
            <FSTableRowItem
              :text="`#${item.voucher.id}`"
              :truncate="-5"
              :to="{ name: 'VoucherDetails', params: { id: item.voucher.id } }"
            />
            <FSTableRowItem :text="`#${item.id}`" :truncate="-5" />

            <FSTableRowItem :text="item.voucher.title" />
            <FSTableRowItem>
              {{ getRedeemedAt(item.redeemed_at) }}
            </FSTableRowItem>
            <FSTableRowItem :text="item.voucher.voucher_type" />
            <FSTableRowItem>
              {{ showVoucherCredit(item.voucher) }}
            </FSTableRowItem>
            <FSTableRowItem>
              <TimeAgo
                :title="item.voucher.expires_at"
                :datetime="item.voucher.expires_at"
                :auto-update="60"
              />
            </FSTableRowItem>

            <FSTableRowItem>
              <x-status
                :text="item.status"
                :variant="getItemVarient(item.status)"
              />
            </FSTableRowItem>
          </FSTableRow>
        </template>
      </FSTable>
    </content-section>
  </section>
</template>
<script>
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  FSTableFilter,
  onApplyFilterDateRange,
} from '@/components/fs-table'
import { VoucherConfig } from '@/config/VoucherConfig'
import { formatSummaries } from '../helpers'
import XStatus from '@/components/badge/XStatus'
export default {
  name: 'VoucherIndex',
  data: function() {
    return {
      id: '',
      indexDataEndpoint: '',
      details: {},
      indexMetaData: {
        summary: {
          total_invited_voucher: 0,
          total_redeemed_voucher: 0,
          total_pending_voucher: 0,
          total_expired_voucher: 0,
        },
      },
      qso: { append: '', prepend: '?' },
      fstId: 'voucherSummary',
      tableHeaders: [
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.vouchers.table.columns.voucherId'
          ),
          width: '10%',
          sort: 'voucher_id',
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.vouchers.table.columns.redeemId'
          ),
          width: '10%',
          sort: 'redeem_id',
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.vouchers.table.columns.voucherTitle'
          ),
          width: '20%',
          sort: 'title',
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.vouchers.table.columns.redeemedAt'
          ),
          width: '10%',
          sort: 'redeemed_at',
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.vouchers.table.columns.voucherType'
          ),
          width: '10%',
          sort: 'voucher_type',
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.vouchers.table.columns.leftCredit'
          ),
          width: '10%',
          sort: 'balance',
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.vouchers.table.columns.expireDate'
          ),
          width: '10%',
          sort: 'expires_at',
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.vouchers.table.columns.status'
          ),
          width: '15%',
          sort: 'status',
        },
      ],
      filterOptions: [
        {
          key: 'status',
          type: 'checkbox',
          input: [
            { text: 'Invited', value: 'Invited' },
            { text: 'Redeemed', value: 'Redeemed' },
            { text: 'Pending', value: 'Pending' },
            { text: 'Expired', value: 'Expired' },
          ],
          title: 'Status',
        },
      ],
      dateFormat: {
        format: 'DD MMM YY',
      },
    }
  },
  components: {
    ContentSection: () => import('@/components/layout/ContentSection'),
    TitlePlus: () => import('@/components/ui/TitlePlus'),
    DateRangePicker: () =>
      import('@/components/picker/date-range/DateRangePicker'),
    SummaryCard: () => import('@/components/cards/SummaryCard'),
    FSTable,
    FSTableRow,
    FSTableRowItem,
    FSTableFilter,
    XStatus,
  },
  created() {
    this.id = this.$route.params.id
    this.indexDataEndpoint = VoucherConfig.api.riderHistory(this.id)
    this.getRiderAllVoucherDetails()
  },

  methods: {
    onApplyFilterDateRange,
    async getRiderAllVoucherDetails() {
      let { data } = await this.$http.get(this.indexDataEndpoint)
      if (data) {
        this.details = data
      }
    },
    getRedeemedAt(redeemed_at) {
      if (redeemed_at) {
        return this.$UTCAwareTime(redeemed_at, this.dateFormat)
      }
      return '--'
    },
    getItemVarient(status) {
      console.log('item-status', status)
      if (status === 'Invited') return 'blue'
      if (status === 'Redeemed') return 'green'
      if (status === 'Pending') return 'orange'
      return 'red'
    },
    showVoucherCredit(voucher) {
      if (voucher.voucher_type) {
        let prefix =
          voucher.voucher_type === 'RV_CREDIT' ? this.currencySymbol : ''
        let postfix = voucher.voucher_type === 'RV_CREDIT' ? '' : 'mins'
        let amount =
          voucher.voucher_type === 'RV_CREDIT'
            ? voucher.reward_amount
            : parseInt(voucher.reward_amount)

        return `${prefix}${amount} ${postfix}`
      }
      return ''
    },
  },
  computed: {
    currencySymbol() {
      return this?.$org?.default_currency?.symbol || '$'
    },
    getSummaries() {
      return formatSummaries(this.indexMetaData.summary)
    },
  },
}
</script>
<style lang=""></style>
